import { createRouter, createWebHistory , RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Roadmap.vue')
  },
  {
    path: '/mint/:co', // 使用动态段来接收参数
    name: 'mint',
    component: () => import(/* webpackChunkName: "mint" */ '../views/MintView.vue')
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import(/* webpackChunkName: "collections" */ '../views/Collections.vue')
  },
  {
    path: '/bot',
    name: 'bot',
    component: () => import(/* webpackChunkName: "bot" */ '../views/BotView.vue')
  },
  {
    path: '/mints',
    name: 'mints',
    component: () => import(/* webpackChunkName: "mints" */ '../views/MintsView.vue')
  },
  {
    path: '/ai',
    name: 'ai',
    component: () => import(/* webpackChunkName: "mints" */ '../views/AiView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "mints" */ '../views/LoginView.vue')
  },
  {
    path: '/collection/:co',
    name: 'collection',
    component: () => import( '../views/collectionView.vue')
  },

]

const router = createRouter({
  history: createWebHistory('/'),

  routes
})

export default router
