// src/store/useUserStore.ts
import { reactive, readonly } from 'vue';

// 定义用户信息接口
interface User {
  user:any ,
  role:any

}

// 定义状态接口
interface State {
  user: User | null;
}

// 创建响应式状态
const state = reactive<State>({
  user: null,
});

// 设置用户信息
function setUser(user: User | null): void {
  state.user = user;
  // 如果用户信息不为空，则将其存储到localStorage中
  if (user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
    console.log("user",user)
  } else {
    // 如果用户信息为空，则表示用户登出，清除localStorage中的信息
    localStorage.removeItem('userInfo');
  }
}

// 提供一个函数用于初始化状态，比如从localStorage中恢复用户信息
function initUser(): void {
  const storedUserInfo = localStorage.getItem('userInfo');
  if (storedUserInfo) {
    const userInfo: User = JSON.parse(storedUserInfo);
    state.user = userInfo;
  }
}

// 对外提供的useUserStore函数
export default function useUserStore() {
  return {
    state: readonly(state), // 提供外部只读访问
    setUser, // 允许设置用户信息
    initUser, // 允许初始化用户信息
  };
}
