<template>
  <el-container class="maincontainer">
    <el-header>
      <el-menu :router="true" mode="horizontal" class="menu-header">
        <div class="menu-items">
          <el-menu-item index="/">
            <img style="width: 40px" src="/img/logo.png" alt="Sei NFT Data logo" />
          </el-menu-item>
          <el-menu-item index="/mints">Mints</el-menu-item>
          <el-menu-item index="/collections">Collections</el-menu-item>
          <el-menu-item index="/Bot">Bot</el-menu-item>
          <el-menu-item index="/roadmap">Roadmap</el-menu-item>
        </div>



        <div class="login-section">
          <!-- 显示用户信息和退出按钮 -->
          <el-dropdown v-if="userStore.state.user" trigger="hover" class="user-menu-dropdown">
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="wallets"><el-icon>
                    <Edit />
                  </el-icon>Edit Wallets</el-dropdown-item>
                <el-dropdown-item @click="logout"><el-icon>
                    <SwitchButton />
                  </el-icon>Log out</el-dropdown-item>
              </el-dropdown-menu>
            </template>
            <div class="user-menu-trigger">
              <img :src="userStore.state.user.user.avatar" alt="Avatar" class="avatar" />
              <div class="user-details">
                <el-text class="user-name">{{ userStore.state.user.user.name }}</el-text>
                <el-text class="user-role" type="info" size="small">{{ userStore.state.user.role.highestRole
                  }}</el-text>
              </div>
              <el-icon>
                <ArrowDownBold />
              </el-icon>
            </div>
          </el-dropdown>




          <!-- 显示登录按钮 -->
          <el-button v-else @click="loginWithDiscord" type="primary">

            <img src="/img/discord.svg" alt="Twitter" class="svg-icon">
            <span>Login</span>

          </el-button>

        </div>



      </el-menu>
    </el-header>

    <el-main style="--el-main-padding: 10px;">
      <el-row :gutter="0">
        <el-col :span="24">
          <router-view />
        </el-col>
      </el-row>
    </el-main>

    <el-footer>
      <el-divider />
      <div class="social-icons">
        <img src="/img/twitter.svg" @click="openTwitter" />
        <img src="/img/discord.svg" @click="openDiscord" />
      </div>
    </el-footer>
  </el-container>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import useUserStore from './store/useUserStore';
const userStore = inject('userStore') as ReturnType<typeof useUserStore>;



// 定义登出函数
const logout = () => {
  userStore.setUser(null); // 清除全局状态中的用户信息
  localStorage.removeItem('token'); // 清除令牌
};

//管理钱包
const wallets = () => {
  //ele弹窗提示 开发中

};

// 定义登录逻辑
const loginWithDiscord = () => {
  const url = new URL(window.location.href);
  const path = url.pathname + url.hash; // pathname 是路由的路径部分，hash 是哈希模式下的路由
  localStorage.setItem('preLoginUrl', path);
  console.log("将现在的页面保存", path);
  const clientId = '1214641490870149162';
  const redirectUri = encodeURIComponent(process.env.VUE_APP_DCAPI_URL + 'auth/discord');
  const scope = encodeURIComponent('identify');
  const prompt = 'consent';

  window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&prompt=${prompt}`;
};

// 定义打开Twitter和Discord的函数
const openTwitter = () => window.open('https://twitter.com/seinftdata', '_blank');
const openDiscord = () => window.open('https://discord.gg/seinftdata', '_blank');
</script>

<style scoped>
.maincontainer {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 2px;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-items,
.login-section {
  display: flex;
  align-items: center;
}

.el-menu-item {
  display: flex;
  align-items: center;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.social-icons img,
.svg-icon {
  width: 24px;
  height: auto;
  cursor: pointer;
}

.svg-icon {
  margin-right: 5px;
  filter: brightness(0) invert(1);
}

.user-menu-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-menu-trigger {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
}

.user-details {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  align-items: flex-start;
}

.user-name,
.user-role {
  width: 100%;
  text-align: left;
  /* Ensure text is left-aligned */
}

.user-role {
  margin-top: 8px;
  /* Increase spacing between name and role */
}

:focus-visible {
  outline: none;
}



</style>
