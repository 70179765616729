


<template>
  <el-main>

<div class="content"><el-icon class="is-loading">
  <Loading />
</el-icon>Coming Soon </div>
</el-main>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

//跳转到mints路由
const router = useRouter();

 router.push({ name: 'mints' });
</script>




<style scoped>
  .content {
    text-align: center;
    font-size: 2em;
    margin-top: 20vh;
    height: 60vh;
  }
</style>