import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import useUserStore from './store/useUserStore';

const app = createApp(App);

// // 定义自定义指令
// app.mixin({
//   mounted() {
//     if (this.title) {
//       document.title = this.title;
//     } else {
//       document.title = 'SeiNFT Data'; // 默认标题
//     }
//   },
//   watch: {
//     title(newTitle) {
//       document.title = newTitle || 'SeiNFT Data'; // 监听title属性的变化
//     },
//   },
// });


// 创建用户状态管理器实例并初始化用户信息
const userStore = useUserStore();
userStore.initUser();

// 提供 userStore 实例，使其在整个应用中可用
app.provide('userStore', userStore);

// 使用 store、router 和 ElementPlus
app.use(store).use(router).use(ElementPlus);

// 全局注册 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 单独注册 Vue3EasyDataTable 组件
app.component('EasyDataTable', Vue3EasyDataTable);






// 挂载 Vue 应用
app.mount('#app');